var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"m-0 w-100",attrs:{"fluid":""}},_vm._l((_vm.welcome),function(item,index){return _c('b-card',{key:index},[_c('div',{staticClass:"d-flex justify-content-start"}),_c('b-row',{staticClass:"pt-1"},[_c('b-col',{attrs:{"lg":"5"}},[_c('b-form-group',{attrs:{"label":"Message in Spanish","label-for":"textarea-1"}},[_c('div',{class:item.isRequiredEs
                ? ''
                : _vm.isDarkSkin
                ? ' rounded border-danger'
                : 'rounded border-danger'},[_c('skeleton-card',{attrs:{"is-busy":item.isBusy}}),(!item.isBusy)?_c('b-form-textarea',{staticStyle:{"height":"160px","max-height":"200px"},attrs:{"id":"textarea-1","placeholder":"Write a message...","disabled":item.toUpdate,"rows":"3","max-rows":"6"},on:{"input":function($event){return _vm.changeHour(item, 1)}},model:{value:(item.msg_es),callback:function ($$v) {_vm.$set(item, "msg_es", $$v)},expression:"item.msg_es"}}):_vm._e()],1)])],1),_c('b-col',{attrs:{"lg":"5"}},[_c('b-form-group',{attrs:{"label":"Message in English","label-for":"textarea-2"}},[_c('div',{class:item.isRequiredEn
                ? ''
                : _vm.isDarkSkin
                ? ' rounded border-danger'
                : 'rounded border-danger'},[_c('skeleton-card',{attrs:{"is-busy":item.isBusy}}),(!item.isBusy)?_c('b-form-textarea',{staticStyle:{"height":"160px","max-height":"200px"},attrs:{"id":"textarea-2","placeholder":"Write a message...","disabled":item.toUpdate,"rows":"3","max-rows":"6"},on:{"input":function($event){return _vm.changeHour(item, 2)}},model:{value:(item.msg_en),callback:function ($$v) {_vm.$set(item, "msg_en", $$v)},expression:"item.msg_en"}}):_vm._e()],1)])],1),_c('b-col',{attrs:{"lg":"2"}},[_c('b-row',{staticClass:"d-flex justify-content-center"},[_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v("Legend")])]),_c('b-row',[_c('b-input-group',{attrs:{"variant":"outline-secondary","size":"lg","prepend":"@1"}},[_c('b-form-input',{attrs:{"trim":"","disabled":"","placeholder":"FIRST NAME"}})],1),_c('b-input-group',{attrs:{"size":"lg","prepend":"@2"}},[_c('b-form-input',{attrs:{"trim":"","disabled":"","placeholder":"LAST NAME"}})],1),_c('b-input-group',{attrs:{"size":"lg","prepend":"@3"}},[_c('b-form-input',{attrs:{"trim":"","disabled":"","placeholder":"DATE TIME CONTACT"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"lg":"10"}},[_c('div',{staticClass:"d-flex justify-content-center"},[(item.toUpdate)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"warning"},on:{"click":function($event){item.toUpdate = false}}},[_c('feather-icon',{attrs:{"icon":"Edit3Icon"}}),_vm._v(" Update")],1):_c('div',[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"info"},on:{"click":function($event){return _vm.reset(item)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}}),_vm._v(" Reset")],1),_c('b-button',{attrs:{"variant":"primary","disabled":!item.msg_es || !item.msg_en},on:{"click":function($event){return _vm.submit(item)}}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" SAVE")],1)],1)],1)])],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }