import { amgApi } from "@/service/axios"

class MessageService {
  // App Message service start

  async setAppMessage(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/set-app-message",
        params
      )
      return data
    } catch (error) {
      console.log("Error setAppMessage")
    }
  }
  async setUrlApp(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/set-url-app",
        params
      )
      return data
    } catch (error) {
      console.log("Error setUrlApp")
    }
  }
  async getUrlApp(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/get-url-app",
        params
      )
      return data.data
    } catch (error) {
      console.log("Error getUrlApp")
    }
  }
  async getTrackingUrlApp(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/get-tracking-url-app",
        params
      )
      return data
    } catch (error) {
      console.log("Error getTrackingUrlApp")
    }
  }
  async getAppMessages() {
    try {
      const data = await amgApi.post("credit-experts-digital/get-app-messages")
      return data
    } catch (error) {
      console.log("Error getAppMessages")
    }
  }
  async getAppMessage(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/get-app-message",
        params
      )
      return data
    } catch (error) {
      console.log("Error getAppMessage")
    }
  }

  async getNoAnserSms(params) {
    try {
      const { data } = await amgApi.post(
        "credit-experts-digital/get-no-answer-sms",
        params
      )
      return data
    } catch (error) {
      console.log("Error getNoAnserSms")
    }
  }

  async updateNoAnswerSms(params) {
    try {
      await amgApi.post("credit-experts-digital/update-no-answer-sms", params)
      return data
    } catch (error) {
      console.log("Error updateNoAnswerSms")
    }
  }

  // App Message service end

  // Welcome Message start

  async setWelcomeMessage(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/set-welcome-message",
        params
      )
      return data
    } catch (error) {
      console.log("Error setWelcomeMessage")
    }
  }

  async getWelcomeMessage() {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/get-welcome-message"
      )

      return data
    } catch (error) {
      console.log("Error getWelcomeMessage")
    }
  }

  // Welcome Message end

  // Quicks SMS start

  async getQuickSmsAdvanced(body) {
    try {
      const response = await amgApi.post(
        "credit-experts-digital/financial/get-quick-sms-advanced",
        body
      )
      return response
    } catch (e) {
      console.log(e)
    }
  }

  async deleteQuickSms(body) {
    try {
      const response = await amgApi.post(
        "credit-experts-digital/financial/delete-quick-sms",
        body
      )
      return response
    } catch (e) {
      console.log(e)
    }
  }

  async getQuickSms(body) {
    try {
      const response = await amgApi.post(
        "credit-experts-digital/financial/get-quick-sms",
        body
      )
      return response
    } catch (e) {
      console.log(e)
    }
  }

  // Quicks SMS end
}
export default new MessageService()
