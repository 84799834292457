<template>
  <b-container class="m-0 w-100" fluid>
    <b-card v-for="(item, index) in welcome" :key="index">
      <div class="d-flex justify-content-start"></div>
      <b-row class="pt-1">
        <b-col lg="5">
          <b-form-group label="Message in Spanish" label-for="textarea-1">
            <div
              :class="
                item.isRequiredEs
                  ? ''
                  : isDarkSkin
                  ? ' rounded border-danger'
                  : 'rounded border-danger'
              "
            >
              <skeleton-card :is-busy="item.isBusy" />
              <b-form-textarea
                v-if="!item.isBusy"
                id="textarea-1"
                v-model="item.msg_es"
                placeholder="Write a message..."
                @input="changeHour(item, 1)"
                :disabled="item.toUpdate"
                rows="3"
                max-rows="6"
                style="height: 160px; max-height: 200px"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col lg="5">
          <b-form-group label="Message in English" label-for="textarea-2">
            <div
              :class="
                item.isRequiredEn
                  ? ''
                  : isDarkSkin
                  ? ' rounded border-danger'
                  : 'rounded border-danger'
              "
            >
              <skeleton-card :is-busy="item.isBusy" />
              <b-form-textarea
                v-if="!item.isBusy"
                id="textarea-2"
                v-model="item.msg_en"
                placeholder="Write a message..."
                @input="changeHour(item, 2)"
                :disabled="item.toUpdate"
                rows="3"
                max-rows="6"
                style="height: 160px; max-height: 200px"
              ></b-form-textarea>
            </div>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-row class="d-flex justify-content-center">
            <p style="font-weight: bold">Legend</p>
          </b-row>
          <b-row>
            <b-input-group variant="outline-secondary" size="lg" prepend="@1">
              <b-form-input
                trim
                disabled
                placeholder="FIRST NAME"
              ></b-form-input>
            </b-input-group>

            <b-input-group size="lg" prepend="@2">
              <b-form-input
                trim
                disabled
                placeholder="LAST NAME"
              ></b-form-input>
            </b-input-group>

            <b-input-group size="lg" prepend="@3">
              <b-form-input
                trim
                disabled
                placeholder="DATE TIME CONTACT"
              ></b-form-input>
            </b-input-group>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="10" class="text-center">
          <div class="d-flex justify-content-center">
            <b-button
              v-if="item.toUpdate"
              variant="warning"
              @click="item.toUpdate = false"
              class="mr-1"
            >
              <feather-icon icon="Edit3Icon"> </feather-icon>
              Update</b-button
            >
            <div v-else>
              <b-button variant="info" @click="reset(item)" class="mr-1">
                <feather-icon icon="Trash2Icon"> </feather-icon>
                Reset</b-button
              >
              <b-button
                variant="primary"
                :disabled="!item.msg_es || !item.msg_en"
                @click="submit(item)"
              >
                <feather-icon icon="SaveIcon"> </feather-icon>
                SAVE</b-button
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex"
import MessageService from "@/views/ce-digital/sub-modules/settings/views/messages/service/ce-set-messages.service"
import SkeletonCard from "@/views/ce-digital/sub-modules/settings/views/messages/components/welcome-message/SkeletonCard.vue"

export default {
  components: { SkeletonCard },
  data() {
    return {
      isBusy: false,
      startWelcome: [],
      welcome: [
        {
          id: 1,
          title: "Welcome message",
          msg_es: null,
          msg_en: null,
          isRequiredEs: true,
          isRequiredEn: true,
          toUpdate: true,
          isBusy: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    await this.welcomeMessage();
    await this.loadWelcome();
  },
  methods: {
    loadWelcome() {
      this.startWelcome.map((item) => {
        if (this.startWelcome.length > 0) {
          this.welcome[item.id - 1].msg_es = item.msg_es;
          this.welcome[item.id - 1].msg_en = item.msg_en;
          return item;
        } else {
          return item;
        }
      });
    },
    async welcomeMessage() {
      try {
        let result = await MessageService.getWelcomeMessage();
        if (result.status == 200) {
          if (result.data) {
            this.startWelcome = result.data;
          }
          setTimeout(() => {
            this.welcome[0].isBusy = false;
          }, 900);
        }
      } catch (error) {
        throw error;
      }
    },
    reset(item) {
      this.welcome[item.id - 1].msg_es = null;
      this.welcome[item.id - 1].msg_en = null;
    },
    async changeHour(item, type) {
      if (type == 1) {
        if (item.msg_es) {
          this.$set(item, "isRequiredEs", true);
        } else {
          this.$set(item, "isRequiredEs", false);
        }
      }
    },

    async submit(item) {
      if (item.msg_es) {
        if (item.msg_en) {
          try {
            const { data } = await MessageService.getWelcomeMessage();

            if (
              data[0] &&
              data[0].msg_es == item.msg_es &&
              data[0].msg_en == item.msg_en
            ) {
              this.showWarningSwal("IMPORTANT!", "This message already exists");
            } else {
              let response = await this.showConfirmSwal();
              if (response.isConfirmed) {
                this.addPreloader();
                const params = {
                  id: item.id,
                  type: item.title,
                  msg_es: item.msg_es,
                  msg_en: item.msg_en,
                  user: this.currentUser.user_id,
                };

                let result = await MessageService.setWelcomeMessage(
                  params
                );
                if (result.status == 200) {
                  this.$set(item, "isRequiredEs", true);
                  this.$set(item, "isRequiredEn", true);
                  this.$set(item, "toUpdate", true);
                  this.welcomeMessage();
                  this.showToast(
                    "success",
                    "top-right",
                    "Success!",
                    "CheckIcon",
                    "Message created successfully"
                  );
                  this.removePreloader();
                }
              }
            }
          } catch (error) {
            throw error;
          }
        } else {
          this.$set(item, "isRequiredEn", false);
        }
      } else {
        this.$set(item, "isRequiredEs", false);
      }
    },
  },
};
</script>
<style scoped>
.iconPadding {
  padding: 2px 0px 0px 10px;
}
.cardPadding {
  padding: 16px 0 8px 10px;
}
</style>
